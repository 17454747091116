<template>
  <div>
    <!--工具栏-->
    <div id="toolbar" ref="toolbar">
      <div id="toolsbarLeft" ref="toolsbarLeft">
        <!--标题-->
        <Icon type="logo-codepen" size="28" v-show="titleShow"/>
        <div style="margin-top:3px;padding:0 5px;font-size:16px;margin-right:50px;white-space:nowrap;"
             v-show="titleShow" ref="title">
          <slot name="title"></slot>
        </div>

        <slot name="toolsbarLeft">
          <!--靠左工具-->
        </slot>
      </div>
      <div id="toolsbarRight" ref="toolsbarRight">
        <slot name="toolsbarRight">
          <!--靠右工具-->
        </slot>
      </div>
    </div>


    <!--编辑区-->
    <div id="contentarea" ref="contentarea">
      <slot name="contentArea">
      </slot>
    </div>


    <!--  工具条-->
<!--    <div id="toolsbarBottom" ref="toolsbarBottom">-->
<!--      <slot name="toolsbarBottom"></slot>-->
<!--    </div>-->
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      titleShow: false,
    }
  },
  mounted() {
    if (this.$refs.title.innerText != "") {
      this.titleShow = true;
    }
  }
}
</script>

<style scoped>
#toolsbarBottom {
  min-height: 50px;
  background: #eee;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
  border-top: #ddd solid 1px
}

#toolbar {
  min-height: 50px;
  background: #fafafa;
  display: flex;
  top: 0;
  position: fixed;
  min-width: 1200px;
  width: 100%;
  box-shadow: 0 0 1px 1px #ddd;
  z-index: 2;
}

#toolsbarLeft {
  margin: 10px;
  float: left;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

#toolsbarLeft > div > .bread {
  margin-left: 74px;
}

#toolsbarRight {
  margin: 10px;
  float: right;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

#toolsbarRight button, #toolsbarLeft button {
  margin: 0 5px;
}


#contentarea {
  top: calc(55px);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 10px 10px 15px;
  line-height: 1.5em;
  z-index: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.tooldiv {
  line-height: 30px;
  text-align: right;
  white-space: nowrap;
}
</style>
